<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>礼包明细</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
<!--        检索条件：用户id，类型，起止时间，支付状态，分润状态,惠豆/商品-->
<!--        返回列表：id，userid，price，order_no,status,create_time,type,goodsId,address,profit_status,reward_flg-->
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="交易起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="用户ID">
                            <el-input
                                    v-model="where.userId"
                                    placeholder="请输入用户ID"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="商品ID">
                            <el-input
                                    v-model="where.goodsId"
                                    placeholder="请输入商品ID"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="交易状态">
                            <el-select v-model="where.status" clearable placeholder="全部">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in giftBagStatus" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支付类型">
                            <el-select v-model="where.type" placeholder="全部">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in giftBagType" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="礼包类型">
                            <el-select v-model="where.rewardFlg" placeholder="类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in rewardFlg" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="分润状态">
                            <el-select v-model="where.profitStatus" placeholder="分润状态">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in profitStatus" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="()=>{currentPage=1;listData();}">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    icon="el-icon-tickets"
                                    :loading="download">导出数据</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="userId" label="用户Id"></el-table-column>
                    <el-table-column prop="price" label="金额"></el-table-column>
                    <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <span :class="'status'+scope.row.status">{{giftBagStatus[scope.row.status]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="类型">
                        <template slot-scope="scope">
                            {{ giftBagType[scope.row.type] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsId" label="商品ID"></el-table-column>
                    <el-table-column prop="address" label="收货地址"></el-table-column>
                    <el-table-column prop="profitStatus" label="分润状态">
                        <template slot-scope="scope">
                            <span :class="'profitStatus'+scope.row.profitStatus">{{ profitStatus[scope.row.profitStatus] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rewardFlg" label="礼包类型">
                        <template slot-scope="scope">
                            <span :class="'rewardFlg'+scope.row.rewardFlg">{{ rewardFlg[scope.row.rewardFlg] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="交易日期"></el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {giftBagType,profitStatus,rewardFlg,giftBagStatus} from "@/plugins/map.js";
    export default {
        components: {},
        data() {
            return {
                giftBagType,
                profitStatus,
                rewardFlg,
                giftBagStatus,
                list: [],
                date: [],
                loading: false,
                download: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    userId:'',//用户id
                    goodsId:'',//商品id
                    status:'',//交易状态【0-待完成  1-已完成】
                    type:'',//类型【01-商家1000  02-用户9.9  03-用户298  04-用户1000】
                    profitStatus:'',//分润状态【0-未分润  1-已分润  2-不需要分润】
                    rewardFlg:'',//1-惠豆  2-商品
                    startDt:'',//   开始日期【yyyy-MM-dd】
                    endDt:'',//结束日期【yyyy-MM-dd】
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.traderPlanOrderList, this.where).then((res) => {
                    this.list = res.data.records;
                    this.totalData = res.data.total;
                    this.currentPage = res.data.current;
                    this.loading = false;
                });
            },
            exportData() {

            },
            changeDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
    .status0,.rewardFlg0,.profitStatus0{
        color: #E6A23C;
    }
    .status1,.profitStatus1{
        color: #67C23A;
    }
    .status3,.profitStatus2{
        color: #F56C6C;
    }
    .status4,{
        color: #909399;
    }
    /*.OPERATION_CENTER,.AD_PAY{
        color: #409EFF;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #a84a26;
    }*/
</style>
<style>
    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
